<template>
  <div>
    <div id="map">
      <v-btn class="move__btn" :loading="loadingMap" onclick="window.location.reload()">
        <img src="@/assets/icon_refresh.svg" alt="새로고침" />
      </v-btn>

      <div class="custom_typecontrol radius_border">
        <span id="btnRoadmap" class="selected_btn" @click="setMapType('roadmap')">지도</span>
        <span id="btnSkyview" class="btn" @click="setMapType('skyview')">스카이뷰</span>
      </div>
      <!-- 지도 확대, 축소 컨트롤 div 입니다 -->
      <div class="custom_zoomcontrol radius_border"> 
          <span @click="zoomIn()"><img src="https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_plus.png" alt="확대"></span>  
          <span @click="zoomOut()"><img src="https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/ico_minus.png" alt="축소"></span>
      </div>
      

      <div class="bottom__info">
        <div class="info_title">
          <img :src="farm" class="farm_icon">
          PIGRO 농장
        </div>
        <div class="info_value">{{ farmMapInfos.length }} 개</div>
      </div>
    </div>
  </div>
</template>

<script>
import Apis from '@/api/apis'
import dateUtil from "@/utils/custom/dateProperty.js";
var z_number = 0;

export default {
  name: "FarmMap",
  data() {
    return {
      farm: require("@/assets/monitoring/building.svg"),

      farmMapInfos: [],
      markers: [],
      infowindow: null,

      loadingMap: false,
      
    };
  },
  created() {
    Apis.pageCount({
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    } ,() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });
  },
  mounted() {
    // 카카오 객체가 있고, 맵 그리는 준비가 되어있다면 맵 실행
    if (window.kakao && window.kakao.maps) {
      this.initMap();
    } else {
      const script = document.createElement("script");
      /* global kakao */
      script.onload = () => kakao.maps.load(this.initMap);
      script.src =
        // "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appke y=d23283daf49e531d088248a1e4b69deb";  //bh key
        // "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=3c416d95761bb8465763d1091d21e308&libraries=clusterer";     //my key
        "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=4799d9355ed066eb69a917b633b871e4&libraries=clusterer";   //hyerin key
        document.head.appendChild(script);
    }
  },
  methods: {
    initMap() {
      const container = document.getElementById("map");
      const options = {
        center: new kakao.maps.LatLng(36.2683, 127.6358), // 지도의 중심좌표 
        level : 12, // 지도의 확대 레벨
        tileAnimation:true,
      };

      //지도 객체를 등록합니다.
      this.map = new kakao.maps.Map(container, options);
      
      //표시할 농장/사업장목록을 가져롭니다
      this.loadingMap = true;
      // 1차 농장위치 및  개수 처리후
      Apis.listFarmsForMap({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
      } ,(res) => {  // 정상처리
        this.farmMapInfos = res.data;
        // console.log(res.data);

        this.$store.commit("resMessage",res.message);
        this.setClusterer(this.farmMapInfos);
        // this.customOverlay(this.farmMapInfos);
        // this.loadingMap = false;

      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listFarmsForMap 호출 오류",err);
        // this.loadingMap = false;
      })

      // 2차 농장별 상세 지표 처리
      Apis.listFarmsForMap({
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
        detail: true, 
      } ,(res) => {  // 정상처리
        this.farmMapInfos = res.data;
        // console.log(res.data);

        this.$store.commit("resMessage",res.message);
        this.setClusterer(this.farmMapInfos);
        // this.customOverlay(this.farmMapInfos);
        this.loadingMap = false;

      }).catch( (err) => {  // 개별 API 오류 처리 예제
        console.log("listFarmsForMap 호출 오류",err);
        this.loadingMap = false;
      })

    },
    setMapType(maptype) { 
        var roadmapControl = document.getElementById('btnRoadmap');
        var skyviewControl = document.getElementById('btnSkyview'); 
        if (maptype === 'roadmap') {
            this.map.setMapTypeId(kakao.maps.MapTypeId.ROADMAP);    
            roadmapControl.className = 'selected_btn';
            skyviewControl.className = 'btn';
        } else {
            this.map.setMapTypeId(kakao.maps.MapTypeId.HYBRID);    
            skyviewControl.className = 'selected_btn';
            roadmapControl.className = 'btn';
        }
    },

    // 지도 확대, 축소 컨트롤에서 확대 버튼을 누르면 호출되어 지도를 확대하는 함수입니다
    zoomIn() {
        this.map.setLevel(this.map.getLevel() - 1);
    },

    // 지도 확대, 축소 컨트롤에서 축소 버튼을 누르면 호출되어 지도를 확대하는 함수입니다
    zoomOut() {
        this.map.setLevel(this.map.getLevel() + 1);
    },

    setClusterer(farmMapInfos){
      // var imageSrc = require('@/assets/map_marker_green.svg'), // 마커이미지의 주소입니다
      var imageSrc = require('@/assets/map_marker.svg'), // 마커이미지의 주소입니다
      imageSize = new kakao.maps.Size(50, 50); // 마커이미지의 크기입니다
      var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize);


      var clusterer = new kakao.maps.MarkerClusterer({
        map: this.map, // 마커들을 클러스터로 관리하고 표시할 지도 객체 
        gridSize: 60,
        averageCenter: true, // 클러스터에 포함된 마커들의 평균 위치를 클러스터 마커 위치로 설정 
        minLevel: 10, // 클러스터 할 최소 지도 레벨 
        minClusterSize: 1,
        calculator : [2, 5, 20],
        styles: [{ 
                width : '80px', height : '80px',
                background: 'radial-gradient(rgba(142, 197, 252, 1.0), rgba(224, 195, 252, 0.6))',
                borderRadius: '50%',
                color: 'white',
                textAlign: 'center',
                fontSize:'40px',
                fontWeight: 'bold',
                lineHeight: '80px',
                },
                { 
                width : '110px', height : '110px',
                background: 'radial-gradient(rgba(4, 190, 254, 1.0), rgba(68, 129, 235, 0.6))',
                borderRadius: '50%',
                color: 'white',
                textAlign: 'center',
                fontsize:'50px',
                fontWeight: 'bold',
                lineHeight: '110px'
                },

                { 
                width : '130px', height : '130px',
                background: 'radial-gradient(rgba(37, 117, 252, 1.0), rgba(106, 17, 203, 0.6))',
                borderRadius: '50%',
                color: 'white',
                textAlign: 'center',
                fontsize:'50px',
                fontWeight: 'bold',
                lineHeight: '130px'
                },],
      });

      var markers = farmMapInfos.map(i =>
        new window.kakao.maps.Marker({
          position : new kakao.maps.LatLng(i.position[0], i.position[1]),
          image: markerImage,
        }),
      );
      // var overlays = farmMapInfos.map(i =>
      //   new kakao.maps.CustomOverlay({
      //       content: '<div style="background-color:#fff; padding:5px;">'+i.position+'</div>',
      //       position :new kakao.maps.LatLng(i.position[0], i.position[1]),
      //     })
      // );

      clusterer.addMarkers(markers);

      // markers.forEach( (m) => 
      for(let i=0; i<markers.length; i++) {            
        kakao.maps.event.addListener(markers[i],'click',() => {

          if (!document.getElementById(farmMapInfos[i].farm_name)) {

            var content = document.createElement('div');
            content.setAttribute( 'id', farmMapInfos[i].farm_cd );
            content.className = 'overlaybox';

            content.onclick = function() {
              event.stopPropagation(); // 상위박스 클릭이벤트 방지
              overlay.setZIndex(z_number ++);
            };
            
            // 돈방 이름 (상단)
            var title = document.createElement('div');
            title.setAttribute( 'id', farmMapInfos[i].farm_name );
            title.className = 'map-popup-title';

            var room_name = document.createElement('span');
            room_name.className = 'popup-name';
            room_name.appendChild(document.createTextNode(farmMapInfos[i].farm_name));
            title.appendChild(room_name);
            content.appendChild(title);

            // 닫기버튼
            var close = document.createElement('h3');
            close.className = 'close';
            title.appendChild(close);
            content.appendChild(title);

            close.onclick = function() {
              event.stopPropagation(); // 상위박스 클릭이벤트 방지
              overlay.setMap(null);
            };

            var value_content = document.createElement('div');
            value_content.className = 'value_overlaybox';
            content.appendChild(value_content);

            // 온도
            if (farmMapInfos[i].data.temp) {
              var value_temp = document.createElement('div');
              value_temp.className = 'map-popup-value';

              var temp_icon = document.createElement('span');
              temp_icon.className = 'temp_icon';
              value_temp.appendChild(temp_icon);
              value_content.appendChild(value_temp);

              var temp = document.createElement('span');
              // temp.setAttribute( 'id', m.farm_cd + '_temp');
              temp.className = 'popup-value';
              temp.appendChild(document.createTextNode(farmMapInfos[i].data.temp.heighest));
              value_temp.appendChild(temp);
              value_content.appendChild(value_temp);

              var temp_unit = document.createElement('span');
              temp_unit.className = 'popup-value-unit';
              temp_unit.appendChild(document.createTextNode('℃'));
              value_temp.appendChild(temp_unit);
              value_content.appendChild(value_temp);
            }

            // 암모니아
            if (farmMapInfos[i].data.nh3) {
              var value_nh3 = document.createElement('div');
              value_nh3.className = 'map-popup-value';

              var nh3_icon = document.createElement('span');
              nh3_icon.className = 'nh3_icon';
              value_nh3.appendChild(nh3_icon);
              value_content.appendChild(value_nh3);

              var nh3 = document.createElement('span');
              nh3.setAttribute( 'id', farmMapInfos[i].farm_cd + '_nh3');
              nh3.className = 'popup-value';
              nh3.appendChild(document.createTextNode(farmMapInfos[i].data.nh3));
              value_nh3.appendChild(nh3);
              value_content.appendChild(value_nh3);

              var nh3_unit = document.createElement('span');
              nh3_unit.className = 'popup-value-unit';
              nh3_unit.appendChild(document.createTextNode('ppm'));
              value_nh3.appendChild(nh3_unit);
              value_content.appendChild(value_nh3);
            }

            // 황화수소
            if (farmMapInfos[i].data.h2s) {
              var value_h2s = document.createElement('div');
              value_h2s.className = 'map-popup-value';

              var h2s_icon = document.createElement('span');
              h2s_icon.className = 'h2s_icon';
              value_h2s.appendChild(h2s_icon);
              value_content.appendChild(value_h2s);
            
              var h2s = document.createElement('span');
              h2s.setAttribute( 'id', farmMapInfos[i].farm_cd + '_h2s');
              h2s.className = 'popup-value';
              h2s.appendChild(document.createTextNode(farmMapInfos[i].data.h2s));
              value_h2s.appendChild(h2s);
              value_content.appendChild(value_h2s);

              var h2s_unit = document.createElement('span');
              h2s_unit.className = 'popup-value-unit';
              h2s_unit.appendChild(document.createTextNode('ppm'));
              value_h2s.appendChild(h2s_unit);
              value_content.appendChild(value_h2s);
            }

            var buttonContainer = document.createElement('div');
            buttonContainer.className = 'popup-buttons';

            var monitorBtn = document.createElement('button');
            monitorBtn.className = 'popup-button';
            monitorBtn.appendChild(document.createTextNode('요약'));
            monitorBtn.onclick = function() {
              window.open('about:blank').location.href = 
              `/monitoring/summary?cc=${farmMapInfos[i].company_cd}&cn=${farmMapInfos[i].company_name}&fc=${farmMapInfos[i].farm_cd}&fn=${farmMapInfos[i].farm_name}`
            };

            var baroBtn = document.createElement('button');
            baroBtn.className = 'popup-button';
            baroBtn.appendChild(document.createTextNode('상세'));
            baroBtn.onclick = function() {
              window.open('about:blank').location.href = 
              `/monitoring/cardroominfo?cc=${farmMapInfos[i].company_cd}&cn=${farmMapInfos[i].company_name}&fc=${farmMapInfos[i].farm_cd}&fn=${farmMapInfos[i].farm_name}`
            };
            buttonContainer.appendChild(monitorBtn);
            buttonContainer.appendChild(baroBtn);

            content.appendChild(buttonContainer);


            var overlay = new kakao.maps.CustomOverlay({
              map:null,
              content: content,
              xAnchor: 0.5, // 커스텀 오버레이의 x축 위치입니다. 1에 가까울수록 왼쪽에 위치합니다. 기본값은 0.5 입니다
              yAnchor: 1.4, // 커스텀 오버레이의 y축 위치입니다. 1에 가까울수록 위쪽에 위치합니다. 기본값은 0.5 입니다
              position: markers[i].getPosition(),
            });

            overlay.setZIndex(z_number ++);
            overlay.setMap(this.map);
          } else {
            document.getElementById(farmMapInfos[i].farm_cd).remove();
          }
        })
      }

      kakao.maps.event.addListener(clusterer, 'clustered', function(clusters) {
        for(var i=0; i<clusters.length; i++){            
          var cluster = clusters[i];

        var clusterinfo = cluster.getClusterMarker().getContent();
        // console.log(cluster.getClusterMarker().getContent())

          // 각 클러스터의 overlay에 mouseover 이벤트를 등록합니다.
          clusterinfo.addEventListener('mouseover', function() {
              if(!this.classList.contains('div_over')) {      
                  this.classList.add('div_over');
              }
          });
          
          // 각 클러스터의 overlay에 mouseout 이벤트를 등록합니다.
          clusterinfo.addEventListener('mouseout', function() {
              if(this.classList.contains('div_over')) {
                  this.classList.remove('div_over');
              }
          });
      }
    });
    },
  },
}
</script>

<style lang="scss" scoped >
#map {
  width: 100%;
  height: 100vh;
}
.map__content{
  z-index:99;
}
.move__btn{
z-index:2;
margin:10px;
border-radius: 50%;
background-image: linear-gradient(to top, #4c6852 0%, #9bab9f 100%);
color:#3a4f4f;
letter-spacing: -0.74px;
font-size:20px !important;
height:60px !important;
width:60px !important;
}

.bottom__info{
  z-index:3;
  position: fixed;
  bottom: 60px;
  right: 50px;
  width:250px;
  height:200px;
  border-radius: 30px;
  letter-spacing: -2px;
  text-align:center;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.4);
}
.info_title{
  font-size:30px;
  color:white;
  background-image: linear-gradient(to top, #4c6852 0%, #9bab9f 100%);
  height:80px;
  line-height:80px;
  border-radius:30px 30px 0 0;
}
.info_value{
  font-size:35px;
  line-height:110px;
}
.farm_icon{
  width:30px;
  height:30px;
  filter: invert(94%) sepia(43%) saturate(16%) hue-rotate(357deg) brightness(160%) contrast(100%);
}
.color_light{
  background-color:#9EB0A2;
}
.color_brand{
  background-color:#3a4f4f;
}
.div_over{
  /* background-color:#3a4f4f !important; */
  transition: all 0.3s linear;
  transform: scale( 1.3 ) !important;
}
.button {
  margin: 0 3px;
}
 
::v-deep .close {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 17px;
  height: 17px;
  background: url('../assets/value/overlay_close.png');
}
::v-deep .close:hover {
  cursor: pointer;
}
::v-deep .overlaybox{
  width:100%;
  max-width:210px;
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  border-radius: 20px;
  transition:all 500ms linear;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.4);

}
::v-deep .overlaybox:after{
  content:'';
  position: absolute;
  margin-left: -11px;
  left: 50%; 
  bottom: -12px;
  width: 22px;
  height: 12px;
  background:url(https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png) no-repeat 0 bottom;
}

::v-deep ::v-deep .value_overlaybox{
  padding:5px 5px 5px 5px;
}
::v-deep .map-popup-title{
  border-radius: 20px 20px 0 0;
  background-image: linear-gradient(60deg, #96deda 0%, #50c9c3 100%);
  color:white;
  overflow: hidden;
  padding:10px 50px 10px 15px;
  width:100%;
  margin-right:20px;
}
::v-deep .popup-name{
  font-size:1.2rem;
  font-weight:600;
  width:100%;
}
::v-deep .map-popup-value{
  padding:5px 10px 0 10px;
  display:flex;
  justify-content: space-evenly; 
}
::v-deep .popup-value-title{
  letter-spacing: -0.74px;
  font-weight:600;
}
::v-deep .popup-value{
  width:75px;
  text-align: right;
  font-size:1.2rem;
}
::v-deep .popup-value-unit{
  margin-left:5px;
  margin-top:4px;
  font-size:0.8rem;
}
::v-deep .popup-buttons{
  display:flex;
  justify-content: center;
  font-size:14px;
}
::v-deep .popup-button{
  margin-bottom:10px;
  width:70px;
  height:30px;
  background-color:#4c6852;
  margin:5px 3px 8px 3px;
  color:white;
  border-radius: 20px;
  font-weight:600;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.3);
}
/* 아이콘 */
::v-deep .temp_icon{
  margin-top:2px;
  /* margin-right:10px; */
  width: 35px;
  height: 30px;
  background: url('../assets/monitoring/temp.svg') no-repeat;
}
::v-deep .hum_icon{
  margin-top:2px;
  /* margin-right:10px; */
  width: 35px;
  height: 30px;
  background: url('../assets/monitoring/humidity.svg') no-repeat;
}
::v-deep .co2_icon{
  margin-top:3px;
  /* margin-right:10px; */
  width: 35px;
  height: 30px;
  background: url('../assets/monitoring/co2.svg') no-repeat;
}
::v-deep .nh3_icon{
  margin-top:3px;
  /* margin-right:10px; */
  width: 35px;
  height: 30px;
  background: url('../assets/monitoring/nh3.svg') no-repeat;
}
::v-deep .h2s_icon{
  margin-top:3px;
  /* margin-right:10px; */
  width: 35px;
  height: 30px;
  background: url('../assets/monitoring/h2s.svg') no-repeat;
}

.radius_border{
  border:1px solid #919191;
  border-radius:5px;
}     
.custom_typecontrol {
  position:absolute;
  top:10px;
  right:10px;
  overflow:hidden;
  width:133px;
  height:30px;
  margin:0;
  padding:0;
  z-index:2;
  font-size:12px;
}
.custom_typecontrol span {
  display:block;
  width:65px;
  height:30px;
  float:left;
  text-align:center;
  line-height:30px;
  cursor:pointer;
}
.custom_typecontrol .btn {
  background:#fff;
  background:linear-gradient(#fff,  #e6e6e6);
}       
.custom_typecontrol .btn:hover {
  background:#f5f5f5;
  background:linear-gradient(#f5f5f5,#e3e3e3);
}
.custom_typecontrol .btn:active {
  background:#e6e6e6;
  background:linear-gradient(#e6e6e6, #fff);
}    
.custom_typecontrol .selected_btn {
  color:#fff;background:#425470;
  background:linear-gradient(#425470, #5b6d8a);
}
.custom_typecontrol .selected_btn:hover {
  color:#fff;
}   
.custom_zoomcontrol {
  position:absolute;
  top:50px;right:10px;
  width:36px;
  height:80px;
  overflow:hidden;
  z-index:2;
  background-color:#f5f5f5;
} 
.custom_zoomcontrol span {
  display:block;
  width:36px;
  height:40px;
  text-align:center;
  cursor:pointer;
}     
.custom_zoomcontrol span img {
  width:15px;
  height:15px;
  margin-top:12px;
  border:none;
  z-index:99;
}             
.custom_zoomcontrol span:first-child{
  border-bottom:1px solid #bfbfbf;
}  
</style>
